.container {
	// padding-left: calc(120px - 15px);
	// padding-right: calc(120px - 15px);
	padding-left: 0 !important;
	padding-right: 0 !important;
	max-width: 1364px;

	@media screen and (max-width: 1440px) {
		padding-left: 80px !important;
		padding-right: 80px !important;
	}

	@media screen and (max-width: 768px) {
		padding-left: 30px !important;
		padding-right: 30px !important;
	}



	@media screen and (min-width: 768px) {
		width: 96%;
		padding-left: 30px;
		padding-right: 30px;
	}


	@media screen and (min-width: 576px) {
		width: 100%;
		padding-left: 30px;
		padding-right: 30px;
	}


	// @media (min-width: 768px) {
	//     width: 100vw;
	//     max-width: 100%;
	// }


	// @media (min-width: 1200px){
	//     width: 1140px;
	//     max-width: 100%;
	// }

	// @media (min-width: 1800px) {
	//     width: 1600px;
	//     max-width: 100%;
	// }

	&.container--white {
		background-color: #fff;
	}
}

.bg--white {
	background-color: #fff;
}

.bg--white-light-grey {
	background: #ffffff; /* Old browsers */
background: -moz-linear-gradient(left, #ffffff 0%, #ffffff 50%, #fbfbfb 50%); /* FF3.6-15 */
background: -webkit-linear-gradient(left, #ffffff 0%,#ffffff 50%,#fbfbfb 50%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to right, #ffffff 0%,#ffffff 50%,#fbfbfb 50%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#fbfbfb',GradientType=1 ); /* IE6-9 */


	@media screen and (max-width: 768px) {
		background: #ffffff;
	}

}