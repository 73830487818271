
	input {
		border:none;
		background-image:none;
		background-color:transparent;
		-webkit-box-shadow: none;
		-moz-box-shadow: none;
		box-shadow: none;	
	}

.input--floating {
	max-width: 95%;

    label {
      color: $grey;
      font-size: 16px;
      line-height: 19px;
      position: absolute;
      top: 21px;
      pointer-events: none;
      @include transition(.7s);

      &.has-value {
        font-size: 12px;
        top: -6px;
      }
    }

    input, textarea {
    	padding-left: 0;
      height: 48px;
      margin-top: 8px;
       margin-bottom: 10px;
      background-color: transparent;
      border: none;
      color: $dark-grey;
      border-bottom: solid 2px $grey;
      margin-right: 32px;
      text-transform: none !important;
      @include border-radius(0px);

      @media screen and (max-width: 599px){
        margin-top: 10px;
        height: 47px !important;
      }



      ~ span {
        display: block;
        width: 0;
        height: 3px;
        background: $black;
        position: absolute;
        top: 53px;
        @include border-radius(0px);

        @media screen and (max-width: 599px){
          top: 54px;
        }


      }

      &:focus {
      	color: $black;
      	background-color: transparent;
        border-bottom: solid 2px $black;


	      ~ span {
	        background: transparent;




	      }

      }

      &:focus ~ label {
        font-size: 12px;
        top: -6px;
        @include transition(700ms);
      }

      &:focus ~ span {
        width: calc(100% - 32px);
        @include transition(700ms);
      }
    }

    textarea {
    	height: auto;
    	~ span {
    		top: 64px;

        @media screen and (max-width: 599px){
          top: 54px;
        }

    	}
    }



}

	::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
		font-family: ff-tisa-sans-web-pro, sans-serif;
	   	color: $dark-grey !important;
	}