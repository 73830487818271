h1, h2, h3, h4, h5, h6 {
	font-family: ff-tisa-sans-web-pro, sans-serif;
	font-weight: bold;
	color: #000;
}

h1 {
	font-size: 80px;
 
	@media screen and (max-width: 576px) {
		font-size: 14vw;
	}

	&.title--styled {
		color: #fff;
		display: inline-block;
		padding-left: 58px;
		position: relative;
		&:before {
			content: "";
			position: absolute;
			left: 0;
			bottom: 0;
			width: 10px;
			height: 0;
			background-color: $grey-blue;
			animation: .5s cubic-bezier(0.785, 0.135, 0.15, 0.86) .5s 1 slideInUp forwards;
		}

		&:after {
			content: "";
			position: absolute;
			right: -22px;
			width: 50px;
			height: 50px;
			opacity: 0;
			bottom: 0;
			border-radius: 3px;
			z-index: -1;
			background-color: $grey-blue;
			animation: .8s cubic-bezier(1.000, -0.195, 0.000, 1.015) .8s 1 slideInFromLeft forwards;

			@media screen and (max-width: 768px) {
				width: 40px;
				height: 40px;
			}

			@media screen and (max-width: 599px) {
				display: none;
			}


		}

		@media screen and (max-width: 768px) {
			padding-left: 6vw;
		}

		@media screen and (max-width: 599px) {
			padding-left: 8vw;
		}

	}
}

h2 {
	font-size: 48px;

	@media screen and (max-width: 567px) {
		font-size: 10vw;
	}

}

h3 {
	font-size: 24px;
}

p {
	font-family: ff-tisa-sans-web-pro, sans-serif;
	font-size: 18px;
	color: $dark-grey;
	&.subtitle {
		font-size: 24px;


		@media screen and (max-width: 576px) {
			font-size: 20px;
		}


	}

	&.p--large {
		font-size: 20px;
	}
}

@keyframes slideInFromLeft {
    0% {
    	opacity: 0;
        right: 400px;
    }
    100% {
    	opacity: 1;
        right: -22px;
    }
}

@keyframes slideInUp {
    0% {
    	height: 0;
    }
    100% {
    	height: 100%;
    }
}