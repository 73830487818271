.nav-link {
	display: inline-flex;
	font-family: ff-tisa-sans-web-pro, sans-serif;
	letter-spacing: 1.2px;
	font-size: 18px;
	color: #fff;
	transition: all .25s ease-in-out;
	position: relative;
	font-weight: bold;

	&:after {
		transition: all .25s ease-in-out;
		content: "";
		background-color: $grey-blue;
		position: absolute;
		width: 0;
		height: 4px;
		top: 44px;
	}


	&:hover {
		color: #fff;
		&:after {
			content: "";
			background-color: $grey-blue;
			position: absolute;
			width: 100%;
			height: 4px;
			top: 44px;

			@media screen and (max-width: 576px) {
				display: none;
			}

		}



		
	}

}

.active {
	.nav-link {

		&:after {
			content: "";
			background-color: $grey-blue;
			position: absolute;
			width: 100%;
			height: 4px;
			left: 0;
			top: 44px;
		}
	}
}

