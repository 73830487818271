.img--shadow {
	position: relative;
	-webkit-box-shadow: 1px 1px 50px 0px rgba(0,0,0,0.1);
	-moz-box-shadow: 1px 1px 50px 0px rgba(0,0,0,0.1);
	box-shadow: 1px 1px 50px 0px rgba(0,0,0,0.1);
	transition: all .15s ease-out;

	&:hover {
		z-index: 1;
		transform: translateY(-4px);
		-webkit-box-shadow: 1px 1px 50px 0px rgba(0,0,0,0.2);
		-moz-box-shadow: 1px 1px 50px 0px rgba(0,0,0,0.2);
		box-shadow: 1px 1px 50px 0px rgba(0,0,0,0.2);

	}
}

// .img--large {
// 	width: 730px;
// 	height: auto;
// }

// .img--small {
// 	width: 609px;
// 	height: auto;
// }

.img--customer {
	max-width: 90%;
}

.img--grid {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin-bottom: 25px;

	.img--large { 
		width: calc(53.5%);
		height: 100%;
	}

	.img--small {
		width: calc(44.6%);
		height: 100%;
	}

	// @media screen and (max-width: 1440px) {
	// 	img {
	// 		&:nth-child(1) {

	// 		}
	// 	}	
	// }

	// @media screen and (max-width: 1140px) {
	// 	img {
	// 		height: 32vh;
	// 		width: auto;
	// 	}	
	// }

	@media screen and (max-width: 768px) {
		margin-bottom: 0;

		img {
			width: 100% !important;
			height: 100%;
			max-height: 100%;
			margin-bottom: 25px;
		}	
	}

}
