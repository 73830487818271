
.work__grid {
	@media screen and (max-width: 960px) {
		flex-direction: column;
	}
}

.work__grid-item {
	background-color: #fff;
	min-height: 633px;
	position: relative;
	background-repeat: no-repeat;
	background-position: left bottom;
	background-size: contain;
	-webkit-box-shadow: 1px 1px 50px 0px rgba(0,0,0,0.1);
	-moz-box-shadow: 1px 1px 50px 0px rgba(0,0,0,0.1);
	box-shadow: 1px 1px 50px 0px rgba(0,0,0,0.1);
	transition: all .15s ease-out;

	&:hover {
		z-index: 1;
		transform: translateY(-4px);
		-webkit-box-shadow: 1px 1px 50px 0px rgba(0,0,0,0.2);
		-moz-box-shadow: 1px 1px 50px 0px rgba(0,0,0,0.2);
		box-shadow: 1px 1px 50px 0px rgba(0,0,0,0.2);

	}

	@media screen and (max-width: 960px) {
		background-color: #fff;
		margin-bottom: 24px;
		min-height: 550px;
		background-position: center bottom;
	}


	@media screen and (max-width: 540px) {
		background-color: #fff;
		margin-bottom: 24px;
		min-height: 430px;
		background-position: center bottom;
	}



	&.bg--end {
		background-position: right bottom;
	}

	&.bg-blue {
		color: #fff;
		background-color: $light-blue;


		.work__grid-title,
		.work__grid-subtitle {
			color: #fff;
		}

	}

	&.bg-purple {
		color: #fff;
		background-color: $med-blue;

		.work__grid-title,
		.work__grid-subtitle {
			color: #fff;
		}

	}

	&.bg-d-blue {
		color: #fff;
		background-color: $med-dark-blue;

		.work__grid-title,
		.work__grid-subtitle {
			color: #fff;
		}

	}	

	// img {
	// 	align-self: flex-end;
	// }
}

.work__grid-item--large {
	flex: 0.53;

	@media screen and (max-width: 960px) {
		flex: 1;
	}
}

.work__grid-item--small {
	flex: 0.446;
	background-color: #fff;

	@media screen and (max-width: 960px) {
		flex: 1;
	}
}



.work__grid-title {
	font-weight: 600;
}

.work__grid-subtitle {
	font-family: ff-tisa-sans-web-pro, sans-serif;
	font-weight: normal;
	font-size: 14px;
	text-transform: uppercase;
	opacity: 0.5;
}