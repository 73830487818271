.header {
	min-height: 570px;

	&.header--hero {
		height: 100vh;

		@media screen and (max-width: 480px) {
			margin-top: -8vh;
		}

	}

	@media screen and (max-width: 768px) {
		min-height: 300px;
	}

	p {
		color: #fff;
	}

}