.mobile-nav {
	position: absolute;
	left: -600px;
	top: 0;
	right: 0;
	bottom: 0;
	background-color: $dark-blue;
	transition: all .5s cubic-bezier(.86,0,.07,1);
	width: 100%;
	z-index: 999;

	&.nav--visible {
		left: 0;
	}
}