.btn {
	 display: inline-block;
    font-family: futura-pt, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 1!important;
    text-align: center;
    text-decoration: none;
    color: #fff;
    text-transform: uppercase;
    background-color: $light-blue;
    border: 2px solid $light-blue;
    border-radius: 50px;
    cursor: pointer;
    transition: all .25s ease-in-out;
    padding-top: 20px !important;
    padding-bottom: 20px !important;
    letter-spacing: 3px;

    &:hover {
	    background-color: darken($light-blue, 8%);
	    border: 2px solid darken($light-blue, 8%);
	    color: #fff;


    }

	&.btn--bordered {
		border-color: $grey-blue;
	    color: #fff;
	    background-color: transparent;

	    &:hover {
		    background-color: $grey-blue;
		    border: 2px solid $grey-blue;
		    color: #fff;
	    }

	}



  @media screen and (max-width: 576px) {
    display: block;
  }

  &.btn--pink {

    background-color: $pink;
    border: 2px solid $pink;


    &:hover {
      background-color: darken($pink, 8%);
      border: 2px solid darken($pink, 8%);
      color: #fff;
    }

  }

}

  .btn--scroll {
      cursor: pointer;

        svg {
          #Oval {
             transition: all .25s ease-in-out;
          }
      }

      &:hover {
        svg {
          #Oval {
            stroke-opacity: 1;
          }
      }
    }
  }

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 2px rgba($light-blue, 0.4);
  }
  70% {
      box-shadow: 0 0 0 12px rgba($light-blue, 0);
  }
  100% {
      box-shadow: 0 0 0 0 rgba($light-blue, 0);
  }
}

@keyframes pulseBordered {
  0% {
    box-shadow: 0 0 0 2px rgba(#fff, 0.2);
  }
  70% {
      box-shadow: 0 0 0 12px rgba(#fff, 0);
  }
  100% {
      box-shadow: 0 0 0 0 rgba(#fff, 0);
  }
}

@keyframes pulsePink {
  0% {
    box-shadow: 0 0 0 2px rgba($pink, 0.4);
  }
  70% {
      box-shadow: 0 0 0 12px rgba($pink, 0);
  }
  100% {
      box-shadow: 0 0 0 0 rgba($pink, 0);
  }
}