$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1800px
) !default;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1600px
) !default;

$spacer: 14px;
$spacer-x: $spacer !default;
$spacer-y: $spacer !default;
$spacers: (
  0: (
    x: 0,
    y: 0
  ),
  1: (
    x: ($spacer-x),
    y: ($spacer-y)
  ),
  2: (
    x: ($spacer-x * 2),
    y: ($spacer-y * 2)
  ),
  3: (
    x: ($spacer-x * 3),
    y: ($spacer-y * 3)
  ),
  4: (
    x: ($spacer-x * 4),
    y: ($spacer-y * 4)
  ),
  5: (
    x: ($spacer-x * 5),
    y: ($spacer-y * 5)
  )
) !default;

$dark-blue: #070c1d;
$med-dark-blue: #1f2137;
$grey-blue: #202434;
$med-blue: #29167e;
$light-blue: #336dff;
$black: #000;
$grey: #dfdfdf;
$dark-grey: #8e949d;
$pink: #ea4c89;
$black: #060c1d;



$body-bg:       $dark-blue !default;
$body-color:    $grey !default;