

.stagger {
	position: relative;
	opacity: 0;
}

.mobile-stagger {
	opacity: 0;
}

.elevated {
	position: relative;
	z-index: 999;
}

html, body {
	@media screen and (max-width: 768px) {
		overflow-x: hidden;
	}	
}


.color--white {
	color: #fff;

	&:hover {
		color: #fff;
	}
}

.color--grey {
	color: rgba(255,255,255,0.5);

	&:hover {
		color: rgba(255,255,255,0.5);
	}
}

hr {
	border-width: 2px;
	border-color: $grey-blue;
}

@mixin transform($transforms) {
	   -moz-transform: $transforms;
	     -o-transform: $transforms;
	    -ms-transform: $transforms;
	-webkit-transform: $transforms;
          transform: $transforms;
}