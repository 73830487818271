.link--styled {
	font-family: ff-tisa-sans-web-pro, sans-serif;
	position: relative;
	display: inline-flex;
	z-index: 1;
	font-weight: normal;
	

	&:after {
		transition: all .25s ease-in-out;
		content: "";
		background-color: $grey-blue;
		position: absolute;
		width: calc(100% + 4px);
		height: 4px;
		bottom: -4px;
		left: -2px;
		z-index: -1;
		border-radius: 1px;
	}


	&:hover {
		text-decoration: none;
		&:after {
			content: "";
			background-color: $grey-blue;
		//	opacity: 0.4;
			height: 100%;
		}
		
	}
}

.link--social {
	color: $light-blue;
	transition: all .25s ease-in-out;
		&:hover {
			svg {
				transition: all .25s ease-in-out;
				fill: $light-blue !important;
			}
		}
}



.contact {
	.link--social {
	color: $dark-grey;
	transition: all .25s ease-in-out;
			path {
				transition: all .25s ease-in-out;
				fill: $dark-grey !important;
			}
		&:hover {
			svg {
				path {
				fill: $light-blue !important;
				}
				
			}
		}
	}
}


.link--contact {
	color: #000;
	&:hover {
		text-decoration: none;
	}
}


footer {
	.link--styled {
		padding-top: 8px;
		&:after {
			transition: all .25s ease-in-out;
			content: "";
			background-color: $grey-blue;
			position: absolute;
			width: calc(100% + 4px);
			height: 2px;
			bottom: -8px;
			left: -2px;
			z-index: -1;
			border-radius: 1px;

			@media screen and (max-width: 599px) {
				bottom: -25px;
			}
		}
	}
}