/* Default custom select styles */
div.cs-select {
	display: inline-block;
	vertical-align: middle;
	position: relative;
	text-align: left;
	background: #fff;
	z-index: 100;
	width: 100%;
	max-width: 500px;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

div.cs-select:focus {
	outline: none; /* For better accessibility add a style for this in your skin */
}

.cs-select select {
	display: none;
}

.cs-select span {
	display: block;
	position: relative;
	cursor: pointer;
	padding: 1em;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

/* Placeholder and selected option */
.cs-select > span {
	padding-right: 3em;
	padding-left: 0;
}

.cs-select > span::after,
.cs-select .cs-selected span::after {
	speak: none;
	position: absolute;
	top: 50%;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.cs-select > span::after {
	//content: '\25BE';
	right: 1em;
}

.cs-select .cs-selected span::after {
	//content: '\2713';
	margin-left: 1em;
}

.cs-select.cs-active > span::after {
	-webkit-transform: translateY(-50%) rotate(180deg);
	transform: translateY(-50%) rotate(180deg);
}

div.cs-active {
	z-index: 200;
}

/* Options */
.cs-select .cs-options {
	position: absolute;
	overflow: hidden;
	width: 100%;
	background: transparent;
	visibility: hidden;
}

.cs-select.cs-active .cs-options {
	visibility: visible;
}

.cs-select ul {
	list-style: none;
	margin: 0;
	padding: 0;
	width: 100%;
}

.cs-select ul span {
	padding: 1em;
}

.cs-select ul li.cs-focus span {
	background-color: #ddd;
}

/* Optgroup and optgroup label */
.cs-select li.cs-optgroup ul {
	padding-left: 1em;
}

.cs-select li.cs-optgroup > span {
	cursor: default;
}


div.cs-skin-elastic {
	background: transparent;
	font-family: ff-tisa-sans-web-pro, sans-serif;
	font-size: 1rem;
	color: $dark-grey;
}

@media screen and (max-width: 30em) {
	div.cs-skin-elastic { font-size: 1em; }
}

.cs-skin-elastic > span {
	background-color: transparent;
	z-index: 100;
}

.cs-skin-elastic > span::after {
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
}

.cs-skin-elastic .cs-options {
	overflow: visible;
	background: transparent;
	opacity: 1;
	visibility: visible;
	padding-bottom: 1.25em;
	pointer-events: none;
}

.cs-skin-elastic.cs-active .cs-options {
	pointer-events: auto;
}

.cs-skin-elastic .cs-options > ul::before {
	content: '';
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	-webkit-transform: scale3d(1,0,1);
	transform: scale3d(1,0,1);
	background: #fff;
	-webkit-transform-origin: 50% 0%;
	transform-origin: 50% 0%;
	-webkit-transition: -webkit-transform 0.3s;
	transition: transform 0.3s;
}

.cs-skin-elastic.cs-active .cs-options > ul::before {
	-webkit-transform: scale3d(1,1,1);
	transform: scale3d(1,1,1);
	-webkit-transition: none;
	transition: none;
	-webkit-animation: expand 0.2s ease-out;
  	animation: expand 0.2s ease-out;
}

.cs-skin-elastic .cs-options ul li {
	opacity: 0;
	-webkit-transform: translate3d(0,-25px,0);
	transform: translate3d(0,-25px,0);
	-webkit-transition: opacity 0.15s, -webkit-transform 0.15s;
	transition: opacity 0.15s, transform 0.15s;
}

.cs-skin-elastic.cs-active .cs-options ul li {
	-webkit-transform: translate3d(0,0,0);
	transform: translate3d(0,0,0);
	opacity: 1;
	-webkit-transition: none;
	transition: none;
	-webkit-animation: bounce 0.2s ease-out;
  	animation: bounce 0.2s ease-out;
}

/* Optional delays (problematic in IE 11/Win) */
/*
.cs-skin-elastic.cs-active .cs-options ul li:first-child {
	-webkit-animation-delay: 0.1s;
	animation-delay: 0.1s;
}

.cs-skin-elastic.cs-active .cs-options ul li:nth-child(2) {
	-webkit-animation-delay: 0.15s;
	animation-delay: 0.15s;
}

.cs-skin-elastic.cs-active .cs-options ul li:nth-child(3) {
	-webkit-animation-delay: 0.2s;
	animation-delay: 0.2s; 
}

.cs-skin-elastic.cs-active .cs-options ul li:nth-child(4) {
	-webkit-animation-delay: 0.25s;
	animation-delay: 0.25s;
} 

/* with more items, more delays declarations are needed */


.cs-skin-elastic .cs-options span {
	background-repeat: no-repeat;
	background-position: 1.5em 50%;
	background-size: 2em auto;
	padding: 0.8em 1em 0.8em 1em;
}

.cs-skin-elastic .cs-options span:hover,
.cs-skin-elastic .cs-options li.cs-focus span,
.cs-skin-elastic .cs-options .cs-selected span {
	color: darken($dark-grey, 8%);
}

.cs-skin-elastic .cs-options .cs-selected span::after {
	content: '';
}

.language--select {
	font-family: ff-tisa-sans-web-pro, sans-serif;
	font-weight: bold;
	width: 88px;
	height: 55px;
	border: 4px solid rgba(255,255,255,0.1);
	border-radius: 44px;
	text-align: center;
	background: url('https://png.icons8.com/ios-glyphs/10/ffffff/expand-arrow.png') no-repeat 58px 18px!important;

	span {
		padding: 10px 32px;
	}

	.cs-options > ul::before {
		background-color: transparent;
	}

	.cs-options span {
		text-align: center;
		padding: 0.8em 1em;

		&:hover &.cs-selected {
			color: #fff;
		}
	}

}



@-webkit-keyframes expand { 
	0% { -webkit-transform: scale3d(1,0,1); }

	100% { -webkit-transform: scale3d(1,1,1); }
}

@keyframes expand { 
	0% { -webkit-transform: scale3d(1,0,1); transform: scale3d(1,0,1); }

	100% { -webkit-transform: scale3d(1,1,1); transform: scale3d(1,1,1); }
}


@-webkit-keyframes bounce { 
	0% { -webkit-transform: translate3d(0,-25px,0); opacity:0; }
	100% { -webkit-transform: translate3d(0,0,0); opacity: 1; }
}

@keyframes bounce { 
	0% { -webkit-transform: translate3d(0,-25px,0); transform: translate3d(0,-25px,0); opacity:0; }
	100% { -webkit-transform: translate3d(0,0,0); transform: translate3d(0,0,0); opacity: 1; }
}
